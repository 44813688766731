
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleSigninBtnComponent } from '../common/google-signin-btn/google-signin-btn.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IframeComponent } from '../iframe/iframe.component';
import { PricingCardComponent } from '../plan-and-pricing/pricing-card/pricing-card.component';
import { PspInfoTabsComponent } from '../deal-pages/new-common-components/psp-info-tabs/psp-info-tabs.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    GoogleSigninBtnComponent,
    IframeComponent,
    PricingCardComponent,
    PspInfoTabsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports: [
    GoogleSigninBtnComponent,
    IframeComponent,
    PricingCardComponent,
    PspInfoTabsComponent
  ]
})
export class CommonSharedModule { }
