<div class="footer_section background_config lozad" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/footer_bg.png)'}">
  <div class="filler_slant_div"></div>
  <div class="page_container">
    <div class="footer_wrapper aos-init aos-animate" data-aos="slide-up">
      <div class="branding_section" data-aos="slide-up">
        <div class="logo link_head"><img loading="lazy" class="lozad"
            [src]="cdn_url+'/assets/images/e_gmat_logo_lg.png'" data-loaded="true"><span
            class="brand_name">e-GMAT</span></div>
        <div class="brand_slogan desktop">
          EMPOWERING DREAMS
          <br>
          CHANGING LIVES
        </div>
        <div class="brand_slogan mobile">
          EMPOWERING DREAMS | CHANGING LIVES
        </div>
      </div>
      <div class="footer_links_section" data-aos="slide-up">
        <div class="footer_links_column">
          <div class="link_head">Links</div>
          <div class="footer_link"><a routerLink="/">Home</a></div>
          <div class="footer_link"><a routerLink="/gmat-course-online">Courses</a></div>
          <div class="footer_link"><a href="https://e-gmat.com/blogs/">Blog</a></div>
          <!-- <div class="footer_link"><a routerLink="/faq">FAQ</a></div> -->
          <div class="footer_link"><a class="footer_link_faq" routerLink="/faqs">FAQ</a></div>
          <div class="footer_link"><a routerLink="/pricing">Buy</a></div>
        </div>
        <div class="footer_links_column">
          <div class="link_head">Company</div>
          <div class="footer_link">
            <a routerLink="/gmat-course-online">Instructors</a>
          </div>
          <div class="footer_link">
            <a routerLink="/about-us">Team</a>
          </div>
          <div class="footer_link">
            <a href="https://growth.e-gmat.com/">Careers</a>
          </div>
          <div class="link_head social_media_icons_container mobile">
            <span class="social_media_icons">Follow us</span><br>
            <a target="_blank" href="https://www.facebook.com/E-Gmat-499275643430980">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/facebook_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/facebook_icon_hover.png'">
              </span>
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/rajat-sadana-ba459a">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/linkedin_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/linkedin_icon_hover.png'">
              </span>
            </a>
            <a target="_blank" href="https://www.youtube.com/user/eGMATconcepts">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/youtube_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/youtube_icon_hover.png'">
              </span>
            </a>
            <a target="_blank" href="https://twitter.com/e_GMAT">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/twitter_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/twitter_icon_hover.png'">
              </span>
            </a>
          </div>
        </div>
        <div class="footer_links_column contact_section desktop">
          <div class="link_head social_media_icons_container">
            <span class="social_media_icons">Follow us</span>
            <a target="_blank" href="https://www.facebook.com/E-Gmat-499275643430980">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/facebook_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/facebook_icon_hover.png'">
              </span>
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/rajat-sadana-ba459a">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/linkedin_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/linkedin_icon_hover.png'">
              </span>
            </a>
            <a target="_blank" href="https://www.youtube.com/user/eGMATconcepts">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/youtube_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/youtube_icon_hover.png'">
              </span>
            </a>
            <a target="_blank" href="https://twitter.com/e_GMAT">
              <span class="icon_container">
                <img loading="lazy" class="icon_default lozad"
                  [src]="cdn_url+'/assets/images/twitter_icon_default.png'">
                <img loading="lazy" class="icon_hover lozad"
                  [src]="cdn_url+'/assets/images/twitter_icon_hover.png'">
              </span>
            </a>
          </div>
          <div class="link_head">Contact us</div>
          <div class="support_text">
            If you need assistance or guidance of any kind, please email us at <a class="hover_effect"
              href="mailto:support@e-gmat.com">support@e-gmat.com</a>
          </div>
          <div class="privacy_text">
            Read our <a class="hover_effect" routerLink="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </div>
      <div class="contact_us_section contact_section mobile">
        <div class="link_head">Contact us</div>
        <div class="support_text">
          If you need any kind of assistance or guidance please email us at <a class="hover_effect"
            href="mailto:support@e-gmat.com">support@e-gmat.com</a>
        </div>
        <div class="privacy_text">
          Read our <a class="hover_effect" routerLink="/privacy-policy">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</div>
