export const seo_data = {
  "login": {
      "title": "Login - e-GMAT",
      "meta_tags": [
          {
              "charset": "UTF-8"
          },
          {
              "name": "robots",
              "content": "noindex,nofollow"
          },
          {
              "property": "og:locale",
              "content": "en_US"
          },
          {
              "property": "og:type",
              "content": "article"
          },
          {
              "property": "og:title",
              "content": "Login - e-GMAT"
          },
          {
              "property": "og:url",
              "content": "https://e-gmat.com/login"
          },
          {
              "property": "og:site_name",
              "content": "e-GMAT"
          },
          {
              "property": "article:author",
              "content": "abhi.testotp@egmat.com"
          },
          {
              "name": "twitter:card",
              "content": "summary"
          },
          {
              "name": "twitter:title",
              "content": "Login - e-GMAT"
          }
      ],
      "link_tags": [
          {
              "rel": "preconnect",
              "href": "https://cdn.e-gmat.com/"
          },
          {
              "rel": "shortcut icon",
              "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
          },
          {
              "rel": "canonical",
              "href": "https://e-gmat.com/login"
          }
      ],
      "json_ld": null
  },
  "learning-login": {
      "title": "Login - e-GMAT",
      "meta_tags": [
          {
              "charset": "UTF-8"
          },
          {
              "property": "og:locale",
              "content": "en_US"
          },
          {
              "property": "og:type",
              "content": "article"
          },
          {
              "property": "og:title",
              "content": "Login - e-GMAT"
          },
          {
              "property": "og:url",
              "content": "https://e-gmat.com/learning-login"
          },
          {
              "property": "og:site_name",
              "content": "e-GMAT"
          },
          {
              "property": "article:author",
              "content": "abhi.testotp@egmat.com"
          },
          {
              "name": "twitter:card",
              "content": "summary"
          },
          {
              "name": "twitter:title",
              "content": "Login - e-GMAT"
          }
      ],
      "link_tags": [
          {
              "rel": "preconnect",
              "href": "https://cdn.e-gmat.com/"
          },
          {
              "rel": "shortcut icon",
              "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
          },
          {
              "rel": "canonical",
              "href": "https://e-gmat.com/learning-login"
          }
      ],
      "json_ld": null
  },
  "privacy-policy": {
      "title": "privacy policy - e-GMAT",
      "meta_tags": [
          {
              "charset": "UTF-8"
          },
          {
              "property": "og:locale",
              "content": "en_US"
          },
          {
              "property": "og:type",
              "content": "article"
          },
          {
              "property": "og:title",
              "content": "privacy policy - e-GMAT"
          },
          {
              "property": "og:url",
              "content": "https://e-gmat.com/privacy-policy"
          },
          {
              "property": "og:description",
              "content": "Privacy Policy “GMAT” and other GMAC™ trademarks are registered trademarks of the Graduate Management Admission Council™. The Graduate Management Admission Council™ does not endorse, nor is affiliated in any way with YourPerfectScore or www.e-gmat.com. The ..."
          },
          {
              "property": "og:site_name",
              "content": "e-GMAT"
          },
          {
              "property": "article:author",
              "content": "abhi.testotp@egmat.com"
          },
          {
              "name": "twitter:card",
              "content": "summary"
          },
          {
              "name": "twitter:description",
              "content": "Privacy Policy “GMAT” and other GMAC™ trademarks are registered trademarks of the Graduate Management Admission Council™. The Graduate Management Admission Council™ does not endorse, nor is affiliated in any way with YourPerfectScore or www.e-gmat.com. The ..."
          },
          {
              "name": "twitter:title",
              "content": "privacy policy - e-GMAT"
          }
      ],
      "link_tags": [
          {
              "rel": "preconnect",
              "href": "https://cdn.e-gmat.com/"
          },
          {
              "rel": "shortcut icon",
              "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
          },
          {
              "rel": "canonical",
              "href": "https://e-gmat.com/privacy-policy"
          }
      ],
      "json_ld": null
  },
  "terms-and-conditions": {
      "title": "Terms and conditions - e-GMAT",
      "meta_tags": [
          {
              "charset": "UTF-8"
          },
          {
              "property": "og:locale",
              "content": "en_US"
          },
          {
              "property": "og:type",
              "content": "article"
          },
          {
              "property": "og:title",
              "content": "Terms and conditions - e-GMAT"
          },
          {
              "property": "og:url",
              "content": "https://e-gmat.com/terms-and-conditions"
          },
          {
              "property": "og:description",
              "content": "Terms & Conditions of Use Welcome to YourPerfectScore, a self paced learning system for your GMAT Preparations.e-gmat.com is a wholly owned subsidiary of YourPerfectScore LLC and this agreement governs the terms and conditions for e-gmat.com ..."
          },
          {
              "property": "og:site_name",
              "content": "e-GMAT"
          },
          {
              "property": "article:author",
              "content": "abhi.testotp@egmat.com"
          },
          {
              "name": "twitter:card",
              "content": "summary"
          },
          {
              "name": "twitter:description",
              "content": "Terms & Conditions of Use Welcome to YourPerfectScore, a self paced learning system for your GMAT Preparations.e-gmat.com is a wholly owned subsidiary of YourPerfectScore LLC and this agreement governs the terms and conditions for e-gmat.com ..."
          },
          {
              "name": "twitter:title",
              "content": "Terms and conditions - e-GMAT"
          }
      ],
      "link_tags": [
          {
              "rel": "preconnect",
              "href": "https://cdn.e-gmat.com/"
          },
          {
              "rel": "shortcut icon",
              "href": "//cdn.e-gmat.com/assets/icons/favicon.ico"
          },
          {
              "rel": "canonical",
              "href": "https://e-gmat.com/terms-and-conditions"
          }
      ],
      "json_ld": null
  },
  "forgot-password": {
      "title": "forgot password - e-GMAT",
      "meta_tags": [
          {
              "name": "robots",
              "content": "noindex, nofollow"
          }
      ],
      "link_tags": [],
      "json_ld": null
  },
  "password-reset-success": {
      "title": "Password reset success - e-GMAT",
      "meta_tags": [
          {
              "name": "robots",
              "content": "noindex, nofollow"
          }
      ],
      "link_tags": [],
      "json_ld": null
  },
  "free-resources-gmat-registration": {
      "title": "Free Preparation Resources for GMAT-Registration Free | e-GMAT",
      "meta_tags": [
          {
              "name": "robots",
              "content": "noindex, nofollow"
          }
      ],
      "link_tags": [],
      "json_ld": null,
      "json_ld": null
  }
}
