import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientService } from './Services/http-client.service';
import { HeaderComponent } from './header/header.component';
import { SigninComponent } from './signin/signin.component';
import { DataService } from './Services/data.service';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './faq/faq.component';
import { VideoPopupComponent } from './video-popup/video-popup.component';
import {AboutUsComponent} from './about-us/about-us-home/about-us.component';
import { AboutUsJourneyComponent } from './about-us/about-us-journey/about-us-journey.component';
import { AboutUsTeamsComponent } from './about-us/about-us-teams/about-us-teams.component';
import { AboutUsFoundersComponent } from './about-us/about-us-founders/about-us-founders.component';
import { AdmitsAndScholarshipComponent } from './admits-and-scholarships/admits-and-scholarship/admits-and-scholarship.component';
import { MbaSuccessStoryComponent } from './admits-and-scholarships/mba-success-story/mba-success-story.component';
import { ScoreSevenFortyPlusComponent } from './admits-and-scholarships/score-seven-forty-plus/score-seven-forty-plus.component';
import { ScoreSevenSixtyComponent } from './admits-and-scholarships/score-seven-sixty/score-seven-sixty.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetSuccessComponent } from './password-reset-success/password-reset-success.component';
import { RegistrationComponent } from './registration/registration.component';
import { FlipClockComponent } from './flip-clock/flip-clock/flip-clock.component';
import { ClockComponent } from './flip-clock/clock/clock.component';
import { ClockLabelComponent } from './flip-clock/clock-label/clock-label.component';
import {CartComponent} from './cart/cart-home/cart.component';
import {SignInSignUpComponent} from './cart/signin-signup/signin-signup.component';
import {PaymentScreenComponent} from './cart/payment-screen/payment-screen.component';
import { CommonSharedModule } from './common-shared/common-shared.module';
import { FaqScreenComponent } from './faq-screen/faq-screen.component';
import { LoginService } from './Services/login.service';
import { CommonService } from './Services/common.service';
import { UrlService } from './Services/UrlService';
import { ModalService } from './Services/modal.service';
import { APP_BASE_HREF, TitleCasePipe } from '@angular/common';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { ScoreGraphPieComponent } from './home-page/score-graph-pie/score-graph-pie.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewDealPageHeaderComponent } from './deal-pages/new-common-components/new-deal-page-header/new-deal-page-header.component';
import { ActivityRedirectComponent } from './common/activity-redirect/activity-redirect.component';
import { ChartsDataComparisonComponent } from './common/charts-data-comparison/charts-data-comparison.component';
import { CheckSegmentAndRedirectComponent } from './common/check-segment-and-redirect/check-segment-and-redirect.component';
import { ContactUsChatComponent } from './common/contact-us-chat/contact-us-chat.component';
import { JoinSessionComponent } from './common/free-session/join-session/join-session.component';
import { GcSuccessStoriesComponent } from './common/gc-success-stories/gc-success-stories.component';
import { LoginComponent } from './common/login/login.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { CustomContentComponent } from './common/redirect/custom-content/custom-content.component';
import { RefreshCourseEnrollmentComponent } from './common/refresh-course-enrollment/refresh-course-enrollment.component';
import { SignInComponent } from './common/sign-in/sign-in.component';
import { SignUpComponent } from './common/sign-up/sign-up.component';
import { VideosRedirectComponent } from './common/videos-redirect/videos-redirect.component';
import { RootPageComponent } from './root/root-page/root-page.component';


declare const google:any;
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SigninComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    NewDealPageHeaderComponent,
    FaqComponent,
    VideoPopupComponent,
    AboutUsComponent,
    AboutUsJourneyComponent,
    AboutUsTeamsComponent,
    AboutUsFoundersComponent,
    AdmitsAndScholarshipComponent,
    MbaSuccessStoryComponent,
    ScoreSevenFortyPlusComponent,
    ScoreSevenSixtyComponent,
    ForgotPasswordComponent,
    PasswordResetSuccessComponent,
    RegistrationComponent,
    FlipClockComponent,
    ClockComponent,
    ClockLabelComponent,
    CartComponent,
    SignInSignUpComponent,
    PaymentScreenComponent,
    FaqScreenComponent,
    ActivityRedirectComponent,
    ChartsDataComparisonComponent,
    CheckSegmentAndRedirectComponent,
    ContactUsChatComponent,
    JoinSessionComponent,
    GcSuccessStoriesComponent,
    LoginComponent,
    PageNotFoundComponent,
    CustomContentComponent,
    RefreshCourseEnrollmentComponent,
    SignInComponent,
    SignUpComponent,
    VideosRedirectComponent,
    RootPageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    FontAwesomeModule,
    ScriptLoaderModule,
    CommonSharedModule
  ],
  providers: [
    HttpClientService,
    DataService,
    ModalService,
    UrlService,
    CommonService,
    LoginService,
    TitleCasePipe,
    { provide: APP_BASE_HREF, useValue: environment.googleBtnURL },
    {
      provide: 'RESPONSE',
      useValue: null
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function playerFactory() {
  return player;
}
