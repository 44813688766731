<div class="tab_view_wrapper">
  <div class="psp_section egmat_tab_view" data-numberoftabs="3" data-contentconfig="bubbleChartsData"
    data-aos="slide-up">
    <div class='tab_view_container'>
      <div class='tab_content_container relative w-[100%] overflow-hidden rounded-[10px]'>
        <ng-container *ngFor="let video of videos;let i = index;">
          <div class="tab_content !pt-0 !shadow-none absolute top-0" [ngClass]="classNamesLeft[i]" [ngStyle]="{'transition': 'left 0.3s ease-in-out'}">
            <img loading="lazy" [src]="video['url']">
          </div>
        </ng-container>
      </div>
      <div class='tab_head_container md:!hidden'>
        <ng-container *ngFor="let video of videos; let i = index;">
          <div (click)="change_tab(i)" class="tab_head" [ngClass]="(i == active_index) ? 'active_tab' : ''">
            <span class="tab_text">
              <span class="label">{{video['tab_label']}}</span>
              <span class="duration_label">{{video['tab_duration_label']}}</span>
              <span class="customer_name">{{video['customer_name']}}</span>
            </span>
          </div>
        </ng-container>
        <div class="tab_select" [ngStyle]="{'left': left_tab_select+'%'}"></div>
      </div>
      <div class="md:flex hidden mt-[30px]">
        <div class="w-[5%] relative">
          <div (click)="slideChange('prev')"
            [ngClass]="{'border-[#d2d2d2]': active_index === 0,'border-[#000]': active_index != 0}"
            class="w-[35px] h-[35px] rounded-full border-[3px] border-solid absolute top-1/2 translate-y-[-50%] right-[10px]">
            <fa-icon [ngClass]="{'text-[#d2d2d2]': active_index === 0,'text-[#000]': active_index != 0}"
              [icon]="['fas', 'angle-left']" class="text-[22px]"></fa-icon>
          </div>
        </div>
        <ng-container *ngFor="let video of videos; let i = index;">
          <div *ngIf="i === active_index"
            class="text-[#000000] text-center w-[90%] border-solid border-[3px] border-[#000000] py-[30px]">
            <div class="text-[18px] font-semibold">{{video['tab_label']}}</div>
            <div class="text-[30px] font-bold">{{video['tab_duration_label']}}</div>
            <div class="text-[18px]">{{video['customer_name']}}</div>
          </div>
        </ng-container>
        <div class="w-[5%] relative">
          <div (click)="slideChange('next')"
            [ngClass]="{'border-[#d2d2d2]': active_index === (videos.length-1),'border-[#000]': active_index != (videos.length-1)}"
            class="w-[35px] h-[35px] rounded-full border-[3px] border-solid absolute top-1/2 translate-y-[-50%] left-[10px]">
            <fa-icon
              [ngClass]="{'text-[#d2d2d2]': active_index === (videos.length-1),'text-[#000]': active_index != (videos.length-1)}"
              [icon]="['fas', 'angle-right']" class="text-[22px]"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
