
import { ModalService } from './Services/modal.service';
import {
  NavigationEnd,
  Router,
  Event,
  ActivatedRoute,
} from '@angular/router';
import { HttpClientService } from './Services/http-client.service';
import { DataService } from './Services/data.service';
import { Component, OnInit, OnDestroy, Renderer2, Inject, PLATFORM_ID, APP_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as AOS from 'aos';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { ScriptService } from 'ngx-script-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'e-gmat-home-angular';
  event_script_url = environment.event_script_url;
  subscription;
  dealSubscribe;
  isHeaderWhite: boolean = false;
  HeaderWhitePaths = [
    '/',
    '',
    'pricing',
    'about-us',
    'limited-period-offer',
    'course-offerings-go-2m',
    'course-offerings-go-4m',
    'course-offerings-go-6m',
  ];
  isChatBox = false;
  chatBoxPaths = [
    '/',
    '',
    'pricing',
    'gmat-course-online',
    'faqs',
    'admits-scholarships',
  ];
  HeaderAbsentPaths = [
    'login',
    'learning-login',
    'faq',
    'free-resources-gmat-registration',
    'refresh-course-enrollment',
    'activity-redirect',
    'charts-data-comparison',
    'charts-data-gc-page',
    'check-segment-and-redirect',
    'custom-content',
    'gc-articles',
    'success-stories',
    'upcoming-session',
    'direct-calendly-redirect',
    'gc-success-stories',
    'videos',
    'limited-period-offer',
    'course-offerings-go-2m',
    'course-offerings-go-4m',
    'course-offerings-go-6m',
    'free-session',
    'ft-gmat-prep',
    'ft-gmat-preparation',
    'sc-free-registration',
    'master-pre-thinking',
    'geometry_webinar',
    'strategy-session-registration',
    'number-properties',
    'rc1-webinar',
    'algebra-webinar',
    'ft-gmat-prep-gce',
    'ft-gmat-focus-edition-prep'
  ];
  FooterAbsentPaths = [
    'login',
    'learning-login',
    'faq',
    'forgot-password',
    'password-reset-success',
    'free-resources-gmat-registration',
    'refresh-course-enrollment',
    'activity-redirect',
    'charts-data-comparison',
    'charts-data-gc-page',
    'check-segment-and-redirect',
    'custom-content',
    'gc-articles',
    'success-stories',
    'upcoming-session',
    'direct-calendly-redirect',
    'gc-success-stories',
    'videos',
    'limited-period-offer',
    'course-offerings-go-2m',
    'course-offerings-go-4m',
    'course-offerings-go-6m',
    'free-session',
    'ft-gmat-preparation',
    'ft-gmat-prep',
    'sc-free-registration',
    'master-pre-thinking',
    'geometry_webinar',
    'strategy-session-registration',
    'number-properties',
    'rc1-webinar',
    'algebra-webinar',
    'ft-gmat-prep-gce',
    'ft-gmat-focus-edition-prep'
  ];
  isHeaderVisible: boolean = true;
  isFooterVisible: boolean = true;
  dealPageRoute = false;
  dealPageRoutes = [
    'limited-period-offer',
    'course-offerings-go-2m',
    'course-offerings-go-4m',
    'course-offerings-go-6m',
  ];

  constructor(
    private dataService: DataService,
    private httpClientService: HttpClientService,
    private router: Router,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private scriptService: ScriptService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.addEventScripts();
      AOS.init(environment.aos_config);
    }

    this.subscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const pathname = event.urlAfterRedirects.split('/')[1].split('?')[0];
        if (this.HeaderWhitePaths.includes(pathname)) {
          this.isHeaderWhite = true;
        } else {
          this.isHeaderWhite = false;
        }
        if (this.chatBoxPaths.includes(pathname)) {
          this.isChatBox = true;
        } else {
          this.isChatBox = false;
        }
        if (this.HeaderAbsentPaths.includes(pathname)) {
          this.isHeaderVisible = false;
        } else {
          this.isHeaderVisible = true;
        }
        if (this.FooterAbsentPaths.includes(pathname)) {
          this.isFooterVisible = false;
        } else {
          this.isFooterVisible = true;
        }
        this.dealPageRoute = this.dealPageRoutes.includes(pathname);
        this.route.queryParams.subscribe((params) => {
          if (!params['resetPassword'] && params['showLoginPopup'] != 'true') {
            this.modalService.close();
          }
        });
        this.modalService.close_ft();
        this.modalService.close_webinar_login();
        this.modalService.close_video();
        if (['gmat-course-online'].includes(pathname)) {
          if (isPlatformBrowser(this.platformId)) {
            document
              .getElementById('body')
              ?.classList.add('body-without-overflow-hidden');
          }
        } else {
          if (isPlatformBrowser(this.platformId)) {
            document
              .getElementById('body')
              ?.classList.remove('body-without-overflow-hidden');
          }
        }
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      const script1 = this.renderer2.createElement('script');
      script1.src = `https://accounts.google.com/gsi/client`;
      script1.async = `true`;
      script1.defer = `true`;
      this.renderer2.appendChild(this.document.body, script1);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.dealSubscribe) {
      this.dealSubscribe.unsubscribe();
    }
  }

  addEventScripts() {
    this.httpClientService
    .getVersionJson('version.json?version=' + new Date().getTime())
    .subscribe((data) => {
      this.dataService.updateJsonVersion = data['version'];
    });
    this.scriptService.loadScript('https://www.googletagmanager.com/gtag/js?id=AW-1010091293').subscribe(() => {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).gtag = function() {
        (window as any).dataLayer.push(arguments);
      }
      this.dataService.updateGtagScriptStatus = true;
    });
    this.httpClientService
    .getVersion(this.event_script_url + 'kissmetrics_events/kissmetrics_version.js')
    .subscribe((version) => {
      this.dataService.updateEventScriptVersion = version;
        this.scriptService.loadScript(this.event_script_url + 'analytics_codes_new.js?version=' + version).subscribe(() => {
          this.scriptService.loadScript(this.event_script_url + 'kissmetrics_events/km_event_fire.js?version=' + version).subscribe(() => {
            this.dataService.updateEventScriptStatus = true;
          });
        });
    });
  }
}
