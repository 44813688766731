import { SEOService } from './../../Services/seo.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { seo_data } from '../json/seo.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isLogin: boolean = true;
  cdn_url = environment.cdn_url;
  isLearningLogin: boolean = false;

  meta_tags;
  link_tags;
  subscription;

  constructor(
    private seoService: SEOService,
    private router: Router
  ) {
    const initialUrl = this.router.url;
    this.handleRouteChange(initialUrl);
  }

  private handleRouteChange(url: string): void {
    const pathname = url.split('/')[1];
    if (['learning-login'].includes(pathname)) {
      this.isLearningLogin = true;
      this.seoService.addTags(seo_data['learning-login']);
    } else {
      this.isLearningLogin = false;
      this.seoService.addTags(seo_data['login']);
    }
  }
  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.isLearningLogin) {
      this.seoService.removeTags(seo_data['learning-login']);
    } else {
      this.seoService.removeTags(seo_data['login']);
    }
  }
}
